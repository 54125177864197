import { useTranslation } from 'react-i18next';
import { PageSubNav } from './PageSubNav';

export const usePlayerNavItems = (path = '') => {
  const { t } = useTranslation(['navigation']);

  return [
    {
      id: 'player-dashboard',
      label: t('secondary.dashboard'),
      route: `${path}dashboard`,
    },
    {
      id: 'player-radar',
      label: t('secondary.radar'),
      route: `${path}radar`,
    },
    {
      id: 'player-vis',
      label: t('secondary.pitchVis'),
      route: `${path}vis`,
    },
    {
      id: 'player-matches',
      label: t('secondary.games'),
      route: `${path}matches`,
    },
  ];
};

export const PlayerNav = () => <PageSubNav items={usePlayerNavItems()} />;
