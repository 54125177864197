import { CellValueToDisplay } from '@/components/tables/CellValueToDisplay';
import { useSortTable } from '@/hooks/useSortTable';
import { NestedObject } from '@/types/object';
import { DataTableColumn } from '@/types/table';
import { getMetricKeyFromColumnKey } from '@/utils/metrics';
import { alignTableCell, getFormatRule, getHighlightedColumnIndex, isLowerBetter } from '@/utils/table';
import { getTranslationColumns } from '@/utils/translations';
import { ButtonIcon, ButtonWithFlyout, Icon, Table, Typography } from '@statsbomb/kitbag-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MINUTES_PLAYED_COLUMN } from '@/consts/dataLocker/table';
import { StatusTranslationKey } from '@/types/status';
import { GAME_ID, METRIC_KEY } from '@/consts/searchKeys';
import { useAtomValue } from 'jotai';
import { sectionAtom } from '@/atoms/general';
import { HasVideoAccess } from '../video/HasVideoAccess';
import { PERMANENT_COLUMNS } from './GameAggregatesTable.constants';
import { TableWrapper } from './TableWrapper';
import { ContentState } from '../contentState/ContentState';
import { TeamGameVideosMenu } from '../games/TeamGameVideosMenu';

const ProcessingAggregates = () => {
  const { t } = useTranslation('games');

  return (
    <div className="flex flex-row gap-x-1">
      <Icon variant="Info" size="small" />
      <Typography variant="bodyMedium" as="span">
        {t('processingAggregates')}
      </Typography>
    </div>
  );
};

interface GameAggregatesTableProps {
  data?: NestedObject[];
  isLoading?: boolean;
  visibleColumns?: string[];
  availableColumns?: DataTableColumn[];
  isVideoColumnVisible?: boolean;
}

export const GameAggregatesTable = ({
  data = [],
  isLoading = false,
  visibleColumns = [],
  availableColumns,
  isVideoColumnVisible = false,
}: GameAggregatesTableProps) => {
  const { t } = useTranslation(['events', 'games', 'video']);

  /* TODO (PPC-1568: Finish implementing watch buttons) */
  const [openMenuRow, setOpenMenuRow] = useState<number | null>(null);
  const playVideoButtonText = t('playVideo', { ns: 'video' });
  const { orderBy, handleSort, getSortedState } = useSortTable();
  const section = useAtomValue(sectionAtom);
  const isTeamSection = section === 'team';

  const hasData = data.length > 0;

  const sortedColumnIndex = orderBy ? visibleColumns.findIndex(col => col === orderBy) : -1;
  // TODO (PPC-812: remove isTeamSection condition once implemented player match stats video menu)
  const highlightedColumnIndex = getHighlightedColumnIndex(sortedColumnIndex, isVideoColumnVisible && isTeamSection);

  return (
    <TableWrapper>
      <div className={hasData ? 'overflow-x-auto' : 'overflow-x-hidden'}>
        <Table isHeadSticky withBorder={false} highlightedColumn={highlightedColumnIndex}>
          <Table.Head>
            <Table.Row>
              {/* TODO (PPC-812: remove isTeamSection condition once implemented player match stats video menu) */}
              {isTeamSection && (
                <HasVideoAccess>
                  <Table.HeaderCell textAlign="left" size="regular">
                    <span>{t('gameVideo', { ns: 'games' })}</span>
                  </Table.HeaderCell>
                </HasVideoAccess>
              )}
              {visibleColumns.map((key, index) => {
                const formatRule = getFormatRule(key, availableColumns);
                const { translationKey, translationNs } = getTranslationColumns(key);
                return (
                  <Table.HeaderCell
                    key={key}
                    textAlign={alignTableCell(formatRule)}
                    sortCb={() => handleSort(key, isLowerBetter(formatRule))}
                    sortState={getSortedState(key)}
                    size="regular"
                  >
                    <span data-testid={`column-${index}`}>{t(translationKey, { ns: translationNs })}</span>
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Head>

          {hasData && (
            <Table.Body>
              {data.map((row, rowIndex) => {
                const gameId = String(row['game.gameId']);
                const columnsToRenderValues = row.hasAggregates ? visibleColumns : PERMANENT_COLUMNS;

                return (
                  <Table.Row key={gameId} className="[&:hover_.invisible]:visible [&:focus-within_.invisible]:visible">
                    {/* TODO (PPC-812: Implement player match stats video menu) */}
                    {isTeamSection && (
                      <HasVideoAccess>
                        <Table.DataCell>
                          <ButtonWithFlyout
                            size="small"
                            displayText="right"
                            variant="ghost"
                            icon="ChevronDown"
                            onClick={() => setOpenMenuRow(rowIndex)}
                            onOutsideClick={() => setOpenMenuRow(null)}
                            placement="bottom-start"
                            isOpen={openMenuRow === rowIndex}
                            title={t('watchVideo', { ns: 'games' })}
                          >
                            <TeamGameVideosMenu
                              gameId={Number(row['game.gameId'])}
                              gameTotalGoals={
                                Number(row['aggregates.goalsAndOwnGoals']) +
                                Number(row['aggregates.goalsAndOwnGoalsConceded'])
                              }
                            />
                          </ButtonWithFlyout>
                        </Table.DataCell>
                      </HasVideoAccess>
                    )}
                    {columnsToRenderValues.map(key => {
                      const formatRule = getFormatRule(key, availableColumns);
                      const value = row[key];
                      const linkParams = `?${METRIC_KEY}=${getMetricKeyFromColumnKey(key)}&${GAME_ID}=${gameId}`;

                      return (
                        <CellValueToDisplay key={key} value={value} columnRule={formatRule} eventKey={key}>
                          <HasVideoAccess>
                            {/* Do not show the play button for columns with no video or cells which have an event count of zero */}
                            {key !== MINUTES_PLAYED_COLUMN && formatRule === 'integer' && value !== 0 && (
                              <Link to={`./video${linkParams}`} className="invisible">
                                <ButtonIcon
                                  as="span"
                                  size="small"
                                  variant="ghost"
                                  icon="Play"
                                  title={playVideoButtonText}
                                >
                                  {playVideoButtonText}
                                </ButtonIcon>
                              </Link>
                            )}
                          </HasVideoAccess>
                        </CellValueToDisplay>
                      );
                    })}
                    {!row.hasAggregates &&
                      visibleColumns.map((_, index) =>
                        index !== 0 ? null : (
                          <Table.DataCell key={`${gameId}-processing-aggs`} colSpan={visibleColumns.length}>
                            <ProcessingAggregates />
                          </Table.DataCell>
                        ),
                      )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          )}
        </Table>
      </div>
      {!isLoading && !hasData && <ContentState status={StatusTranslationKey.NO_DATA} />}
      {isLoading && <ContentState status={StatusTranslationKey.LOADING} />}
    </TableWrapper>
  );
};
