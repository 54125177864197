import { useTranslation } from 'react-i18next';
import { PageSubNav } from './PageSubNav';

export const useTeamNavItems = (path = '') => {
  const { t } = useTranslation('navigation');

  return [
    {
      id: 'team-dashboard',
      label: t('secondary.dashboard'),
      route: `${path}dashboard`,
    },
    {
      id: 'team-radar',
      label: t('secondary.radar'),
      route: `${path}radar`,
    },
    {
      id: 'team-vis',
      label: t('secondary.pitchVis'),
      route: `${path}vis`,
    },
    {
      id: 'team-squad',
      label: t('secondary.squad'),
      route: `${path}squad`,
    },
    {
      id: 'team-matches',
      label: t('secondary.games'),
      route: `${path}matches`,
    },
  ];
};

export const TeamNav = () => <PageSubNav items={useTeamNavItems()} />;
