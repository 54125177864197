import { playerEventVideoIdsAtom, playerGameEventDescriptionsAtom } from '@/atoms/queries/player/playerEventData';
import { useSetMetricKey } from '@/hooks/useMetricKey';
import { useSetPlayerId } from '@/hooks/usePlayerId';
import { useSetGameIdForVideos } from '@/hooks/useSetGameIdForVideos';
import { useAtomValue } from 'jotai';
import { MatchStatsVideoLayout } from '@/components/layout/video/MatchStatsVideoLayout';

export const PlayerGamesVideoPage = () => {
  useSetMetricKey();
  useSetPlayerId();
  useSetGameIdForVideos();

  const playerEventVideoIds = useAtomValue(playerEventVideoIdsAtom);
  const playerGameEventDescriptions = useAtomValue(playerGameEventDescriptionsAtom);

  return <MatchStatsVideoLayout videoEventIds={playerEventVideoIds} descriptionsData={playerGameEventDescriptions} />;
};
