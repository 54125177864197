/* istanbul ignore file */
import { Route, Routes } from 'react-router-dom';
import { MatchLayout } from '@/components/layout/match/MatchLayout';
import { NotFound } from '@/pages/NotFound';
import { MatchDashboardPage } from '@/pages/match/dashboard/MatchDashboardPage';

export const MatchRoutes = () => (
  <Routes>
    <Route element={<MatchLayout />}>
      <Route path="dashboard" element={<MatchDashboardPage />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);
