import { useAtomValue } from 'jotai';
import { ReactNode, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { UserDetails } from '@statsbomb/parachute-types';
import { ErrorBoundary } from '@sentry/react';
import { isValidEntityUserAtom } from '@/atoms/user';
import { FETCH_ERROR } from '@/consts/client';
import { StatusTranslationKey } from '@/types/status';
import { AppShellFallbackBase, AppShellFallback } from './layout/AppShellFallback';

export const ErrorPage = ({ errorType, userDetails }: { errorType: string; userDetails?: UserDetails | null }) => {
  const { t } = useTranslation('error');
  let errorMessage = t(`${errorType}.message`);

  if (userDetails && userDetails.entity.start_date)
    errorMessage = errorMessage.replaceAll('{{start_date}}', userDetails.entity.start_date);

  if (userDetails && userDetails.entity.end_date)
    errorMessage = errorMessage.replaceAll('{{end_date}}', userDetails.entity.end_date);

  return (
    <AppShellFallbackBase statusDescription={errorMessage} statusTitle={t(`${errorType}.title`)} statusType="error" />
  );
};

const ShowErrorWithData = ({ children }: { children: React.ReactNode }) => {
  const { valid, error, userDetails } = useAtomValue(isValidEntityUserAtom);
  return valid ? children : <ErrorPage errorType={error} userDetails={userDetails} />;
};

/* 
  This handles the error on the ErrorPage if the user has logged out then we 
  render the auth0 screen (children) else we render the ErrorPage
  the auth0 login screen, else we render the error page
*/
export const invalidEntityFallbackHandler = ({ error, children }: { error: Error; children: ReactNode }) => {
  /* if the error code contains `Fetch error: 401` we know their token is bad or doesn't exist */
  const isUnauthedError = error.message.includes(`${FETCH_ERROR} 401`);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isUnauthedError ? <ErrorPage errorType="no_user" userDetails={null} /> : <>{children}</>;
};

export const HandleInvalidEntityUser = ({ children }: { children: ReactNode }) => (
  <Suspense fallback={<AppShellFallback status={StatusTranslationKey.LOADING} />}>
    <ErrorBoundary fallback={({ error }) => invalidEntityFallbackHandler({ error, children })}>
      <ShowErrorWithData>{children}</ShowErrorWithData>
    </ErrorBoundary>
  </Suspense>
);
