import { Card, CardBody } from '@statsbomb/kitbag-components';
import { useStatus } from '@/hooks/useStatus';
import { StatusTranslationKey } from '@/types/status';
import { ReactNode } from 'react';
import { EmptySideNav, SideNav } from '../navigation/SideNav';
import { EmptyTopBar, TopBar } from '../topBar/TopBar';
import { AppShellBase } from './AppShell';
import { CardHeightWrapper } from '../card/CardHeightWrapper';

export const AppShellFallbackBase = ({
  statusDescription,
  statusTitle,
  statusType,
  topBar = <EmptyTopBar />,
  sideNav = <EmptySideNav />,
  children,
}: {
  statusDescription: string;
  statusTitle: string;
  statusType: string;
  topBar?: ReactNode;
  sideNav?: ReactNode;
  children?: ReactNode;
}) => (
  <AppShellBase topBar={topBar} sideNav={sideNav} statusType={statusType}>
    <div className="p-2 h-full w-full">
      <Card>
        <CardHeightWrapper>
          <CardBody statusDescription={statusDescription} statusTitle={statusTitle} statusType={statusType} />
        </CardHeightWrapper>
      </Card>
      {children}
    </div>
  </AppShellBase>
);

export const AppShellFallback = ({
  topBar,
  sideNav,
  status,
  children,
}: {
  topBar?: ReactNode;
  sideNav?: ReactNode;
  status: StatusTranslationKey;
  children?: ReactNode;
}) => {
  const statusProps = useStatus('appShell', status);

  if (!statusProps.statusType) return null;

  return (
    <AppShellFallbackBase topBar={topBar} sideNav={sideNav} {...statusProps}>
      {children}
    </AppShellFallbackBase>
  );
};

export const AppShellLoadingFallback = ({
  topBar = <TopBar />,
  sideNav = <SideNav />,
  children,
}: {
  topBar?: ReactNode;
  sideNav?: ReactNode;
  children?: ReactNode;
}) => (
  <AppShellFallback topBar={topBar} sideNav={sideNav} status={StatusTranslationKey.LOADING}>
    {children}
  </AppShellFallback>
);

export const AppShellErrorFallback = () => (
  <AppShellFallback status={StatusTranslationKey.ERROR} sideNav={<SideNav />} />
);
