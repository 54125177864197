import { useFilterStateHistory } from '@/hooks/useFilterStateHistory';
import { useOnBackButtonClick } from '@/hooks/useOnBackButtonClick';
import { useTheme } from 'styled-components';
import { AppBar, TopBarInfo } from '@statsbomb/kitbag-components';
import { ReactNode, Suspense } from 'react';
import { SideNav } from '@/components/navigation/SideNav';
import { StatusTranslationKey } from '@/types/status';
import { useApplicationReferenceData } from '@/hooks/dataFetching/useApplicationReferenceData';
import { GenericToast } from '../alerts/GenericToast/GenericToast';
import { AppShellFallback } from './AppShellFallback';

interface AppShellProps {
  topBar: ReactNode;
  subnav?: ReactNode;
  sideNav: ReactNode;
  children: ReactNode;
}

export const AppShellBase = ({
  topBar,
  subnav,
  sideNav,
  children,
  statusType,
}: AppShellProps & { statusType?: string }) => {
  useFilterStateHistory();
  useOnBackButtonClick();

  const theme = useTheme();
  const isTouch = theme.mqMatch('md');

  const testId = `appshell${statusType ? `-${statusType}` : ''}`;

  return (
    <>
      <div className="flex h-full isolate" data-testid={testId}>
        <div className="z-10">{sideNav}</div>
        <div className="flex flex-col w-full overflow-x-hidden grow appbar-wrapper">
          <AppBar version={2}>
            <TopBarInfo isTouch={isTouch}>{topBar}</TopBarInfo>
            {subnav}
          </AppBar>
          {children}
        </div>
      </div>
      <GenericToast />
    </>
  );
};

export const AppShellWithData = (props: AppShellProps) => {
  useApplicationReferenceData();

  return <AppShellBase {...props} />;
};

export const AppShell = ({
  topBar,
  subnav,
  sideNav = <SideNav />,
  children,
}: Omit<AppShellProps, 'sideNav'> & { sideNav?: ReactNode }) => (
  <Suspense fallback={<AppShellFallback status={StatusTranslationKey.LOADING} />}>
    <AppShellWithData topBar={topBar} subnav={subnav} sideNav={sideNav}>
      {children}
    </AppShellWithData>
  </Suspense>
);
