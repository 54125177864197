import { useAtomValue } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { Outlet } from 'react-router-dom';
import { layoutScopeAtoms } from '@/consts/scope';
import { matchIdAtom } from '@/atoms/match/match';
import { useSetMatchId } from '@/hooks/useSetMatchId';
import { ContentState } from '@/components/contentState/ContentState';
import { StatusTranslationKey } from '@/types/status';
import { EntityTopBarBase } from '@/components/topBar/EntityTopBar';
import { AppShell } from '../AppShell';

const MatchLayoutWithData = () => {
  useSetMatchId();
  const matchId = useAtomValue(matchIdAtom);

  return (
    <AppShell topBar={<EntityTopBarBase entityName="Placeholder" />}>
      {matchId ? <Outlet /> : <ContentState status={StatusTranslationKey.LOADING} />}
    </AppShell>
  );
};

export const MatchLayout = () => (
  <ScopeProvider atoms={layoutScopeAtoms}>
    <MatchLayoutWithData />
  </ScopeProvider>
);
