import { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { matchIdAtom } from '@/atoms/match/match';

export const useSetMatchId = () => {
  const { matchId } = useParams();
  if (!matchId) throw new Error('Missing param in URL: matchId');
  const setMatchId = useSetAtom(matchIdAtom);
  useEffect(() => setMatchId(Number(matchId)), [matchId]);
};
