import { useAtom } from 'jotai';
import { navigationOpenAtom } from '@/atoms/navigation';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { SideNavigation, SideNavigationHeader } from '@statsbomb/kitbag-components';
import { useNavigationItems } from '@/hooks/useNavigationItems';
import { ReactNode } from 'react';
import { SupportLinks } from '@/components/topBar/SupportLinks';
import { EmptySideNavLinks, MainSideNavLinks } from '@/components/navigation/SideNavLinks';
import { EmptySideNavFooterLinks, SideNavFooterLinks } from '@/components/navigation/SideNavFooterLinks';
import { ErrorBoundary } from '@sentry/react';
import LogoText from '../../images/logos/hudlStatsbombTextLogo.svg';
import LogoIcon from '../../images/logos/hudlLogo.svg';

const LogoLinkWithData = ({ id, children }: { id: string; children: ReactNode }) => {
  const navigationItems = useNavigationItems();
  const defaultTeamLink = navigationItems.primary.find(item => item.icon === 'TeamSoccer')!;

  return (
    <Link to={defaultTeamLink.path}>
      <div data-testid={`logo-link-${id}`}>{children}</div>
    </Link>
  );
};

/* This is handling an error with the `useNavigationItems` hook which tries to call
the api for the default team and player ids */
const LogoLink = ({ id, children }: { id: string; children: JSX.Element }) => (
  <ErrorBoundary fallback={children}>
    <LogoLinkWithData id={id}>{children}</LogoLinkWithData>
  </ErrorBoundary>
);

const SideNavBase = ({
  SideNavMainComponent,
  SideNavFooterComponent,
}: {
  SideNavMainComponent: React.FC;
  SideNavFooterComponent: React.FC<{ isTouch: boolean }>;
}) => {
  const [isNavOpen, setIsNavOpen] = useAtom(navigationOpenAtom);
  const theme = useTheme();
  const isTouch = theme.mqMatch('md');
  const isNavOpenState = isTouch ? isNavOpen : false;

  return (
    <SideNavigation version={2} isOpen={isNavOpenState} isTouch={isTouch}>
      <SideNavigationHeader
        isOpen={isNavOpenState}
        isTouch={isTouch}
        logoIcon={
          <LogoLink id="icon">
            <LogoIcon />
          </LogoLink>
        }
        logoText={
          <LogoLink id="text">
            <LogoText />
          </LogoLink>
        }
        onClick={isTouch ? () => setIsNavOpen(!isNavOpen) : undefined}
        optionalControl={
          <div className="relative">
            <SupportLinks isMobile />
          </div>
        }
      />
      <SideNavMainComponent />
      <SideNavFooterComponent isTouch={isTouch} />
    </SideNavigation>
  );
};

export const EmptySideNav = () => (
  <SideNavBase SideNavMainComponent={EmptySideNavLinks} SideNavFooterComponent={EmptySideNavFooterLinks} />
);

export const SideNav = () => (
  <ErrorBoundary fallback={<EmptySideNav />}>
    <SideNavBase SideNavMainComponent={MainSideNavLinks} SideNavFooterComponent={SideNavFooterLinks} />
  </ErrorBoundary>
);
