import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import '@statsbomb/kitbag-datavis/dist/style.css';
import './i18n';
import { BrowserRouter, Route } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import { HandleInvalidEntityUser } from '@/components/HandleInvalidEntityUser';
import { Providers } from './components/Providers';
import { PlayerRoutes } from './routes/playerRoutes';
import { DataLockerRoutes } from './routes/dataLockerRoutes';
import { RedirectToLocalised } from './components/routes/RedirectToLocalised';
import { ProtectedRoute } from './components/routes/ProtectedRoute';
import { SentryRoutes } from './utils/errorMonitoring';
import { TeamRoutes } from './routes/teamRoutes';
import { Head } from './components/head/Head';
import Report from './pages/Report';
import { RedirectToTeam } from './components/routes/RedirectToTeam';
import { Settings } from './pages/Settings';
import { ReportsPage } from './pages/report/ReportsPage';
import { MatchRoutes } from './routes/matchRoutes';
import { InternalOnlyRoute } from './components/routes/InternalOnlyRoute';
import { AppShellErrorFallback, AppShellLoadingFallback } from './components/layout/AppShellFallback';
import { EmptyTopBar } from './components/topBar/TopBar';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <Head />
      <BrowserRouter>
        <Providers>
          <ErrorBoundary fallback={<AppShellErrorFallback />}>
            <Suspense fallback={<AppShellLoadingFallback sideNav={null} />}>
              <HandleInvalidEntityUser>
                <SentryRoutes>
                  <Route path="/">
                    <Route
                      path=""
                      element={
                        <AppShellLoadingFallback topBar={<EmptyTopBar />}>
                          <RedirectToLocalised />
                        </AppShellLoadingFallback>
                      }
                    />
                    <Route path=":locale">
                      <Route path="" element={<ProtectedRoute component={RedirectToTeam} />} />
                      <Route path="player/:playerId/*" element={<ProtectedRoute component={PlayerRoutes} />} />
                      <Route path="team/:teamId/*" element={<ProtectedRoute component={TeamRoutes} />} />
                      <Route path="data-locker/*" element={<ProtectedRoute component={DataLockerRoutes} />} />
                      <Route path="report/:reportId" element={<ProtectedRoute component={Report} />} />
                      <Route path="reports" element={<ProtectedRoute component={ReportsPage} />} />
                      <Route
                        path="match/:matchId/*"
                        element={
                          // // TODO (PPC-1751: unhide match dashboard)
                          <InternalOnlyRoute>
                            <ProtectedRoute component={MatchRoutes} />
                          </InternalOnlyRoute>
                        }
                      />
                      <Route path="settings/*" element={<ProtectedRoute component={Settings} />} />
                    </Route>
                  </Route>
                </SentryRoutes>
              </HandleInvalidEntityUser>
            </Suspense>
          </ErrorBoundary>
        </Providers>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
);
